<template>
    <div
        style="background-image:url('img/rank-bg.png');overflow:hidden; min-height:calc(100vh - 0px);background-size: 100% 100%;background-repeat: no-repeat">
        <div class="roomNav display_flex justify-content_flex-center align-items_center">
            <div class="tab" :style="rankCur == index ? 'background-image:url(' + item.active_bg + ')' : 'background-image:url(' + item.default_bg + ')'" v-for="(item, index) in ranklist" :key="index"
                @click="rankchange(index, item.list_type)">
            </div>
        </div>
        <div class="rankType">
            <div class="romitem" style="border-radius:0;" :class="{ 'romName': ranklist[rankCur].cur == index }"
                v-for="(item, index) in ranklist[rankCur].type" :key="index" @click="typechange(index, item.sort_date)">
                {{ item.name }}
            </div>
        </div>

        <div v-if="nodata" class="roomnoData">
            <img :src="'img/noData.png'" alt="">
        </div>

        <div class="room-three display_flex justify-content_flex-center" v-if="topThreeItems.length > 0">
            <div class="display_flex flex-direction_column align-items_center" v-if="topThreeItems.length >= 1"
                @click="checkUser(topThreeItems[0].id)">
                <div class="rom-head1">
                    <img class="rom-img" :src="topThreeItems[0].pic" alt="">
                    <img class="rom-bgimg" src="img/no1/no.png" alt="">
                </div>
                <div class="display_flex align-items_center">
                    <div class="rom-name">{{ topThreeItems[0].nickname }}</div>
                    <div class="rom-level" v-if="rankCur == 0 || rankCur == 1"
                        :style="{ backgroundImage: `url(img/level/level${rankCur}/${topThreeItems[0].user_grade}.png)` }">
                    </div>
                </div>
                <div class="rom-wealth" v-if="topThreeItems[0].differ_display == 2">
                    <template v-if="rankCur == 0">财富值</template>
                    <template v-if="rankCur == 1">魅力值</template>
                    <span>{{ topThreeItems[0].value }}</span>
                </div>
            </div>
            <div class="rom-box2 display_flex flex-direction_column align-items_center" v-if="topThreeItems.length >= 2"
                @click="checkUser(topThreeItems[1].id)">
                <div class="rom-head2">
                    <img class="rom-img" :src="topThreeItems[1].pic" alt="">
                    <img class="rom-bgimg" src="img/no2/no.png" alt="">
                </div>
                <div class="display_flex align-items_center">
                    <div class="rom-name">{{ topThreeItems[1].nickname }}</div>
                    <div class="rom-level" v-if="rankCur == 0 || rankCur == 1"
                        :style="{ backgroundImage: `url(img/level/level${rankCur}/${topThreeItems[1].user_grade}.png)` }">
                    </div>
                </div>
                <div class="rom-wealth" v-if="topThreeItems[1].differ_display == 2">
                    <template v-if="rankCur == 0">财富值</template>
                    <template v-if="rankCur == 1">魅力值</template>
                    <span>{{ topThreeItems[1].value }}</span>
                </div>
            </div>
            <div class="rom-box3 display_flex flex-direction_column align-items_center" v-if="topThreeItems.length >= 3"
                @click="checkUser(topThreeItems[2].id)">
                <div class="rom-head2">
                    <img class="rom-img" :src="topThreeItems[2].pic" alt="">
                    <img class="rom-bgimg" src="img/no3/no.png" alt="">
                </div>
                <div class="display_flex align-items_center">
                    <div class="rom-name">{{ topThreeItems[2].nickname }}</div>
                    <div class="rom-level" v-if="rankCur == 0 || rankCur == 1"
                        :style="{ backgroundImage: `url(img/level/level${rankCur}/${topThreeItems[2].user_grade}.png)` }">
                    </div>
                </div>
                <div class="rom-wealth" v-if="topThreeItems[2].differ_display == 2">
                    <template v-if="rankCur == 0">财富值</template>
                    <template v-if="rankCur == 1">魅力值</template>
                    <template v-if="rankCur == 2">惊喜值</template>
                    <template v-if="rankCur == 3">幸运值</template>
                    <span>{{ topThreeItems[2].value }}</span>
                </div>
            </div>
        </div>
        <div class="rom-list" v-if="listItems.length > 0">
            <div class="rom-item display_flex justify-content_flex-justify align-items_center"
                v-for="(item, index) in listItems" :key="index">
                <div class="display_flex align-items_center" @click="checkUser(item.id)">
                    <div class="text-white item-order">{{ index + 4 }}</div>
                    <img class="item-img" :src="item.pic" alt="">
                    <div>
                        <div class="display_flex align-items_center">
                            <div class="item-name text-white">{{ item.nickname }}</div>
                            <div class="item-level" v-if="rankCur == 0 || rankCur == 1"
                                :style="{ backgroundImage: `url(img/level/level${rankCur}/${item.user_grade}.png)` }"></div>
                        </div>
                        <div class="item-sign text-white">签名:{{ item.signature }}</div>
                    </div>
                </div>
                <div v-if="item.differ_display == 2">
                    <div class="item-weal display_flex flex-direction_column align-items_flex-end">
                        <div v-if="rankCur == 0">财富值</div>
                        <div v-if="rankCur == 1">魅力值</div>
                        <span>{{ item.value }}</span>
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>
  
<script>
import "@/assets/css/rank.css"
import { roomWealthList, roomCharismaList, luckyList } from "@/api/ranking";
export default {
    name: 'room',
    data() {
        return {
            rankCur: 0,
            ranklist: [
                {
                    name: '财富榜',
                    type: [
                        {
                            name: '日榜',
                            sort_date: 'day'
                        },
                        {
                            name: '周榜',
                            sort_date: 'week'
                        },
                        {
                            name: '月榜',
                            sort_date: 'all'
                        }
                    ],
                    cur: 0,
                    active_bg: 'img/caifu_active.png',
                    default_bg: 'img/caifu_default.png'
                }, {
                    name: '魅力榜',
                    type: [
                        {
                            name: '日榜',
                            sort_date: 'day'
                        },
                        {
                            name: '周榜',
                            sort_date: 'week'
                        },
                        {
                            name: '月榜',
                            sort_date: 'all'
                        }
                    ],
                    cur: 0,
                    active_bg: 'img/meili_active.png',
                    default_bg: 'img/meili_default.png'
                }
            ],
            sort_date: 'day',
            topThreeItems: [],
            listItems: [],
            page: 1,
            nodata: false,
        }
    },
    created() {
        let access_token = this.$route.query.access_token
        localStorage.setItem('access_token', access_token)
        this.getData()
    },
    methods: {
        //榜单切换
        rankchange(e, type) {
            this.rankCur = e
            this.sort_date = 'day'
            this.ranklist[e].cur = 0
            this.page = 1
            this.getData()
        },
        //周榜总榜切换
        typechange(e, type) {
            let rankCur = this.rankCur
            this.ranklist[rankCur].cur = e
            this.sort_date = type
            this.page = 1
            this.getData()
        },
        getData() {
            let Params = {
                room_id: this.$route.query.room_id,
                version: this.$route.query.version,
                type: this.sort_date,
                page: this.page,
            }
            if (this.rankCur == 0) {
                roomWealthList(Params).then(response => {
                    const topThreeItems = response.data.slice(0, 3);
                    const listItems = response.data.slice(3);
                    this.topThreeItems = topThreeItems
                    this.listItems = listItems
                    if (response.data.length == 0) {
                        this.nodata = true
                    } else {
                        this.nodata = false
                    }
                },
                    error => {
                        this.$toast(error)
                    }).catch((error) => {
                        this.$toast(error)
                    })
            }
            if (this.rankCur == 1) {
                roomCharismaList(Params).then(response => {
                    const topThreeItems = response.data.slice(0, 3);
                    const listItems = response.data.slice(3);
                    this.topThreeItems = topThreeItems
                    this.listItems = listItems
                    if (response.data.length == 0) {
                        this.nodata = true
                    } else {
                        this.nodata = false
                    }
                },
                    error => {
                        this.$toast(error)
                    }).catch((error) => {
                        this.$toast(error)
                    })
            }
            if (this.rankCur == 2 || this.rankCur == 3) {
                let rank_type = this.rankCur - 1
                let param = { rank_type: rank_type, ...Params }
                luckyList(param).then(response => {
                    const topThreeItems = response.data.rank_list.slice(0, 3);
                    const listItems = response.data.rank_list.slice(3);
                    this.topThreeItems = topThreeItems
                    this.listItems = listItems
                    if (response.data.rank_list.length == 0) {
                        this.nodata = true
                    } else {
                        this.nodata = false
                    }
                },
                    error => {
                        this.$toast(error)
                    }).catch((error) => {
                        this.$toast(error)
                    })
            }
        },
        closePage() {
            let params = '调用'
            this.$bridge.callhandler('closePage', params, (data) => {
                // 处理返回数据
            })
        },
        checkUser(id) {
            let param = id.toString()
            this.$bridge.callhandler('checkUser', param, (data) => {
                // 处理返回数据
            })
        }
    },
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->